import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from "./Footer.scss";

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <p>Copyright Tim Thiel 2024. All Rights Reserved</p>
                <Link to="/impressum">Impressum</Link>
            </div>
        </footer>
    );
};

export default Footer;
