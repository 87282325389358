import React, { useEffect } from 'react';
import { styles } from "./Home.modules.scss";
import ContactForm from './ContactForm';
import IPad from '../components/IPad';
import Button from '../components/Button';
import Button2 from '../components/Button2';
import ReactGA from 'react-ga4';

const Home = () => {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-XXXXXXXXXX');

    // Send a pageview when the Home component is loaded
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <main>
        <div className="home">
          <div className="HauptDiv">
            <img className="mainImage" src='Lebenslauf-Bild-Website.png' alt="Restaurant" />
            <div className="title">
              <h2 className='title1'>Herzlich Willkommen <br/> auf meiner Karriere-Website</h2>
              <h1 className='title2'>Tim Florian Thiel</h1>
              <Button name="About Me" url="/about" />
            </div>
          </div>
          <div className="Hinfo" id="section1">
            <div className="Hinfo1">
              <div className="bild1Div"><img className='bild1' src='SkillsWhite.png' alt="Pizzeria"></img></div>
              <div className="text1">
                <h2>Fähigkeiten</h2>
                <p>Während meines dualen Studiums "Marketingmanagement" habe ich mir die Adobe Programme Illustratior, Indesign, Photoshop und Premiere Pro angeeignet. Außerdem habe ich die Grundlagen der Websiteprogrammierung und das Arbeiten mit den gängigen Office Programmen vertieft. Hierbei habe ich agiles Projektmanagement, sowohl bei Projekten in Studium als auch bei meinem Arbeitgeber, angewandt.</p>
              </div>
            </div>
            <div className="Hinfo2" id="section2">
              <div className="text2">
                <h2>Projektmanagement</h2>
                <p>Ich habe mir in meinem Studium früh angewöhnt, Projekte strukturiert anzugehen. Um dies effizient umzusetzen verwende ich meist agiles Projektmanagement in Form von Scrum. Durch die Ausformulierung anstehender Aufgaben-Tickets stelle ich sicher, dass ich  meine Zeit sinnvoll investiere. Das hat sich besonders in Klausuren-Phasen als sehr hilfreich erwiesen hat. Ob im Team oder alleine, durch ein strukturiertes Arbeiten spare ich nicht nur Zeit, vielmehr erreiche ich insgesamt bessere Ergebnisse, da Projekte sehr zielgerichtet abgearbeitet werden können.</p>
                <div><div className="reservierung"><Button2 name="Über Tim Thiel" url="/about" /></div></div>
              </div>
              <div className="bild2Div"><img className='bild2' src='ShakeHands.jpeg' alt='Pizza'></img></div>
            </div>
          </div>
          <div className="data" id="section3">
            <img src="Projektmanagement.jpeg" className="dataImage" alt="Projektmanagement" />
            <div className="dataText">
              <div className="dataTextLeft">
                <div className="dataTextLeft1">
                  <h3>ADRESSE</h3>
                  <p>Hungerbergweg 30<br />89335 Ichenhausen</p>
                </div>
                <div className="dataTextLeft2">
                  <h3>E-mail</h3>
                  <p>tim.thiel0809@gmail.com</p>
                </div>
                <div className="dataTextLeft3">
                  <h3>Telefon</h3>
                  <p>+49 162 7320 575</p>
                </div>
              </div>
              <div className="dataTextRight">
                <iframe className="googleMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2649.6108690840288!2d10.310534376671685!3d48.387210933748904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47995042abf8724b%3A0x3fd5731f3c4e9ffa!2sHungerbergweg%2030%2C%2089335%20Ichenhausen!5e0!3m2!1sde!2sde!4v1718099177427!5m2!1sde!2sde" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </main>
  
    </div>
  );
};

export default Home;
