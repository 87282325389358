import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styles } from "./Button.scss";

const Button = ({ name, url }) => {
    return (
            <a href={url} className='button1'>{name}</a>
    );
};
Button.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default Button;