import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { styles } from './ContactForm.css';

const ContactForm = () => {
  const form = useRef();
  const [message, setMessage] = useState(''); // Statusnachricht

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_35lctv9', 'template_7o8otgo', form.current, {
        publicKey: 'hc9CG54CGh0nrVI_5',
      })
      .then(
        () => {
          setMessage('Gesendet'); // Setze die Statusnachricht auf "Gesendet"
          form.current.reset(); // Leere die Eingabefelder
        },
        (error) => {
          console.log('FAILED...', error.text);
          setMessage('Fehler beim Senden. Bitte versuchen Sie es erneut.'); // Fehlermeldung
        }
      );
  };

  return (
    <div className='ContactForm'>
      <div className='BigDiv'>
        <div className='ContactTitle'>
          <p>Haben Sie Fragen oder Interesse? <br /> Schreiben Sie mir gerne eine Nachricht!</p>
        </div>
        <div className='ContactFormDiv'>
          <form ref={form} onSubmit={sendEmail}>
            <div className='ContactDiv'>
              <div className='ContactName'><label for="name">Unternehmen / Name</label></div>
              <div><input className='ContactText' type="text" name="user_name" /></div>
            </div>
            <div className='ContactDiv'>
              <div className='ContactName'><label for="email">Ihre E-Mail</label></div>
              <div><input className='ContactText' type="email" name="user_email" /></div>
            </div>
            <div className='ContactDiv'>
              <div className='ContactName'><label for="message">Nachricht</label></div>
              <div><textarea className='ContactMessage' name="message" /></div>
            </div>
            {message && <p className='status-message'>{message}</p>} {/* Statusnachricht anzeigen */}
            <div className='SubmitDiv'>
              <input className='ContactSubmit' type="submit" value="Absenden" />
              <p>Mit dem Absenden der Nachricht stimmen Sie der Verarbeitung der abgefragten Daten <br /> zur Kontaktaufnahme zu</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
