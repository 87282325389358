
import React from 'react';
import { styles } from "./About.modules.scss";
import IPad from '../components/IPad';
import IPad2 from '../components/IPad2';
import Button from '../components/Button';

  const About = () => {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };
  return (
    <div className="about">
      <main>
        <div className="Atitle">
          <h1 className='Atitle1'>Über mich</h1>
        </div>
        <div className='AInfoDiv'>
        <div class="info">
            <h2>Hobbys</h2>
            <p>In meiner Freizeit lege ich großen Wert auf körperliche Fitness und mentale Ausgeglichenheit. Deshalb verbringe ich regelmäßig Zeit im Fitnessstudio, um meine körperliche Gesundheit zu fördern und neue Energie für tägliche Herausforderungen zu tanken. Zusätzlich habe ich eine Leidenschaft für Golf entwickelt. Dieses anspruchsvolle Spiel ermöglicht es mir, im Freien aktiv zu sein, meine Konzentration zu schulen und gleichzeitig den perfekten Ausgleich zum oft hektischen Alltag zu finden.</p>
            <h2>Interessen</h2>
            <p>Beruflich bin ich leidenschaftlich an der Optimierung von Abläufen, der Erschließung neuer Geschäftsmöglichkeiten und der Analyse von Märkten und Konkurrenz interessiert. Bei meinem früheren Arbeitgeber habe ich erfolgreich Meetups organisiert, um Bewerber und Kunden mit den Produkten vertraut zu machen. Darüber hinaus habe ich einen URL-Generator in Excel entwickelt, der die Nachverfolgung und Optimierung von Werbeaktionen erheblich erleichtert. Regelmäßig habe ich Konkurrenzanalysen durchgeführt und diese vor der Geschäftsführung präsentiert, um strategische Einblicke und Handlungsempfehlungen zu geben. Diese Erfahrungen haben meine Fähigkeiten in den Bereichen Prozessoptimierung, Business Development und Marktanalyse weiter gestärkt.</p>
            <h2>Kontinuerliches Lernen</h2>
            <p>Ich habe eine ausgeprägte Leidenschaft für kontinuierliches Lernen und die schnelle Aneignung neuer Programme und Prozesse. Innerhalb kürzester Zeit habe ich mir eigenständig die Design-Programme von Adobe angeeignet und nutze diese nun routiniert für verschiedenste Projekte. Ebenso habe ich mir fundierte Kenntnisse in der Website-Programmierung erarbeitet, was es mir ermöglicht, innovative und benutzerfreundliche digitale Lösungen zu entwickeln. Diese Beispiele sind nur ein kleiner Ausschnitt meiner Fähigkeiten. Ich bin stets bestrebt, mich weiterzuentwickeln und möchte meine Kenntnisse und Fähigkeiten im Bereich Business Development, Sales und Projektmanagement weiter vertiefen. Ich freue mich darauf, neue Prozesse zu erlernen und anzuwenden, um so zum Erfolg und Wachstum des Unternehmens beizutragen.</p>
            <div class="button"><Button name='ZUR SPEISEKARTE' url='/Impressum'/></div>
        </div>
          <div className='AInfo2'>
            <div className='AInfo2Text'>
              <h2>Excel URL-Generator</h2>
              <p>Ich habe einen URL-Generator in Excel entwickelt, der es ermöglicht, eine Basis-URL einzugeben und verschiedene Variablen wie Sprache, Datum und Kampagnenname hinzuzufügen. Das Tool erstellt daraufhin eine angepasste URL, die einfach kopiert und verwendet werden kann. Diese spezifischen URLs enthalten eindeutige Zahlen- und Buchstabenkombinationen, die in einem Content-Management-System (CMS) getrackt werden können. Dadurch lässt sich genau analysieren, welche Kampagnen den größten Erfolg hatten. Auf Basis dieser Daten können gezielte Optimierungen im Marketing vorgenommen werden, um die Effektivität zukünftiger Kampagnen zu steigern.</p>
            </div>
            <div className='AInfo2Bild'><IPad inverse={false} url={"URL-Generator-Video.mov"}/></div>
          </div>
        </div>

        <div class="Ainfo">
          <Button name='Home' url='/'/>
        </div>

        {/*<div className="gallery-container">
          <div className="gallery">
            <img src="/Restaurant.jpeg" alt="Bild 2" className="gallery-image"/>
            <img src="/Pizzaria.jpeg" alt="Bild 1" className="gallery-image"/>
            <img src="/Pizza.jpeg" alt="Bild 2" className="gallery-image"/>
            <img src="/Pizzen.jpeg" alt="Bild 2" className="gallery-image"/>

            {/* Weitere Bilder hier einfügen 
          </div>
        </div>*/}
      </main>
    </div>
  );
};

export default About;
