import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { styles } from './IPad.css';

const IPad = ({inverse, url, light}) => {
  const [playing, setPlaying] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [isLight, setIsLight] = useState(light);

  const handlePause = () => {
    setPlaying(!playing);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleReady = () => {
    setIsLight(false); // Entfernt das Standbild, sobald das Video geladen ist
  };

  return (
    <div className={`ipad-container ${expanded ? 'expanded' : ''}`}>
      <div className={`ipad ${inverse ? 'inverse' : ''}`}>
        <div className="ipad-screen">
          <ReactPlayer
            className="ipad-video"
            url={url}
            playing={playing}
            loop={true}
            width="100%"
            height="100%"
            controls={false}
            light={isLight}
            onReady={handleReady}
          />
        </div>
      </div>
    </div>
  );
};

export default IPad;

