import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from './NavBar.modules.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';


const NavBar = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='NavBar'>
    <nav>
          <div className='nav-left'>
          <a href="/"><img src='Logo_white-blue.svg' alt='Logo'></img>Tim Thiel</a>
          
          </div>
          <div className='nav-middle'>
          <ul className="nav-links" id="nav-links">
            <li><a href="/">HOME</a></li>
            <li><a href="/about">ÜBER MICH</a></li>
            <li><a href="/Impressum">KONTAKT</a></li>
            <div className="menu-icon" id="menu-icon">&#9776;</div>
          </ul>
          </div>
          <div className='nav-right'>
          <FontAwesomeIcon icon={faPhone} size="1x"/>
          <span>+49 162 7320 575 
            </span></div>
        </nav>
        </div>
  );
};

export default NavBar;
