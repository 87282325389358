import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from "./Impressum.modules.css";
import ContactForm from './ContactForm';

const Impressum = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='ImpressumAll'>
            <div className="ititle">
                        <h1>Impressum</h1>
                    </div>
        <div className='impressumDiv'>
            <main>
                <div className="impressum-Site">
                    <div className="impressum">
                        <div className="impressum-content">
                            <h2>Tim Thiel</h2>
                            <p>Adresse: Hungerbergweg 30, 89335 Ichenhausen</p>
                            <p>Telefon: +49 162 7320 575</p>
                            <p>E-Mail: tim.thiel0809@gmail.com</p>
                        </div>
                    </div>
                </div>
            </main>
            <div className='ImpressumContact'>
            <ContactForm/>
            </div>
        </div>
        </div>
    );
};

export default Impressum;
